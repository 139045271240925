import { useContext, useEffect, useState, useCallback } from "react";
import {
  UseReconocimientosRealizados,
  myReconocimientoInterface,
} from "../../common/interfaces/ReconocimientoInterface";
import useLanguage from "./useLanguage";
import ES from "../../common/language/reconocimiento/es.json";
import EN from "../../common/language/reconocimiento/en.json";
import { SearchTable } from "../../utils/functions";
import { useQuery } from "react-query";
import { GetMyReconocimientos } from "../../services/HomeService";
import { AppContext } from "../../context/AppContext";
import { LanguageContext } from "../../context/LanguageContext";
import { modalError } from "../../utils/validaciones";

const useReconocimientosRealizados = (): UseReconocimientosRealizados => {
  const [recoSearch, setRecoSearch] = useState<
    myReconocimientoInterface[] | null
  >(null);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [orden, setOrden] = useState({ campo: "fecha", ascendente: false });
  const appContext = useContext(AppContext);
  const language = useContext(LanguageContext);
  const { dictionary } = useLanguage({ EN, ES });

  const fetchData = useCallback(() => {
    const direccion = orden.ascendente ? "asc" : "desc";
    const query = `${orden.campo} ${direccion}`;
    return GetMyReconocimientos(language.language, page, 10, query);
  }, [language.language, page, orden]);

  const { isLoading: loading, data: recoRealizados } = useQuery({
    queryKey: ["recoRealizados", appContext?.user?.idUsuario, page, orden],
    queryFn: fetchData,
    onSuccess: (data) => {
      setTotalPages(data.cantidadPaginas);
      setRecoSearch(data.data);
    },
    onError: (error: any) => {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Error al traer los Reconocimientos Realizados";
      modalError(
        "Error al traer los Reconocimientos Realizados\n" + errorMessage
      );
    },
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  useEffect(() => {
    if (recoRealizados && recoRealizados.data) {
      setRecoSearch(recoRealizados.data);
    }
  }, [recoRealizados]);

  const handleSearch = (value: string) => {
    const filter = SearchTable(
      recoRealizados.data,
      [
        "tituloTipoReconocimiento",
        "nombreUsuarioReconocido",
        "nombreUsuarioSupervisor",
        "puntos",
        "estado",
      ],
      value
    );
    setRecoSearch(filter);
  };

  const cambiarOrdenamiento = (campo: string) => {
    setOrden((ordenActual) => {
      const esAscendente =
        ordenActual.campo === campo ? !ordenActual.ascendente : true;
      return {
        campo,
        ascendente: esAscendente,
      };
    });
  };

  return {
    loading,
    recoSearch,
    setRecoSearch,
    dictionary,
    handleSearch,
    setPage,
    page,
    totalPages,
    cambiarOrdenamiento,
    orden,
  };
};

export default useReconocimientosRealizados;
