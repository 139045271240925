import { useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper"; // import required modules
import "swiper/css"; // Import Swiper styles
import "swiper/css/pagination"; // Import Swiper styles
import "swiper/css/navigation"; // Import Swiper styles
import { carouselService } from "../../../../services/HomeService";
import { cliente } from "../../../../common/constants/constants";
import { LanguageContext } from "../../../../context/LanguageContext";
import { Box } from "@mui/material";
import { useQuery } from "react-query";
import MiniLoader from "../../../../components/Loaders/MiniLoader/MiniLoader";
import { modalError } from "../../../../utils/validaciones";

interface carouselInterface {
  idHomeCarousel?: number;
  idCliente: number;
  descripcion?: string;
  orden: number;
  imagen?: string;
  leyenda?: string;
}

export default function Carousel() {
  const { language } = useContext(LanguageContext);
  const { isLoading, data: carouselInfo } = useQuery({
    queryKey: [language],
    queryFn: async () => carouselService(cliente, language),
    onError: (error: any) => {
      const errorMessage =
        error instanceof Error ? error.message : "Error al traer el Carousel";
      modalError("Error al traer el Carousel\n" + errorMessage);
    },
  });

  return (
    <Box>
      {isLoading ? (
        <MiniLoader />
      ) : (
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          loop={true}
          autoplay={{
            delay: 6000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
        >
          {carouselInfo !== null &&
            carouselInfo.length > 0 &&
            carouselInfo?.map((el: carouselInterface, index: number) => (
              <SwiperSlide key={`carousel-img-${el.idHomeCarousel}`}>
                <img src={`${el.imagen}`} alt={el.leyenda} width="100%" />
              </SwiperSlide>
            ))}
        </Swiper>
      )}
    </Box>
  );
}
