import { useContext } from 'react';
import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';

import { useNavigate } from 'react-router-dom';
import { RUTAS } from '../constants/rutas';
import { AppContext } from '../../context/AppContext';

function useCustomQuery({
  queryKey,
  queryFn,
  initialData,
  onError,
  ...rest
}: UseQueryOptions<any, any>) {
  const { cerrarSession } = useContext(AppContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const useMyQuery = useQuery<any>({
    queryKey,
    queryFn,
    initialData,
    retry: 1,
    refetchOnMount: false,
    staleTime: 70000,
    onError: (error: any) => {
      onError && onError(error);

      if (error?.response?.status === 401) {
        cerrarSession();
        queryClient.clear();
        navigate(RUTAS.login);
      }
      return null;
    },
    ...rest,
  });
  return { useMyQuery };
}

export default useCustomQuery;
