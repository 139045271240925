import { Usuario } from "../common/interfaces/ComunInterface";
import { fetchContent } from "../utils/fetchUtils";
import { handleServiceError } from "./GiftCardService";

export const GetMiEquipo = async () => {
  try {
    let response = await fetchContent(`/api/usuario/supervisor/usuarios`, {
      method: "GET",
    });
    const responseSorted = response.data.sort(function (
      a: Usuario,
      b: Usuario
    ) {
      if (a.nombre ? a.nombre : "" < (b.nombre ? b.nombre : "")) {
        return -1;
      }
      if (a.nombre ? a.nombre : "" > (b.nombre ? b.nombre : "")) {
        return 1;
      }
      return 0;
    });
    return responseSorted;
  } catch (error: any) {
    console.log("GetMiEquipo error]: " + error);
    throw handleServiceError(error);
  }
};

export const getBudgetSupervisor = async (idUsuario: number | string) => {
  try {
    const response = await fetchContent(`/api/Supervisor/${idUsuario}`);
    return response;
  } catch (error) {
    throw handleServiceError(error);
  }
};
