import React, { createContext, useState } from "react";
import { contextUserInterface } from "../common/interfaces/LoginInterface";
import { Permiso } from "../common/interfaces/Permisos";
import { postLogout } from "../services/LoginServices";

export type AuthUser = {
  nombre: string;
  apellido: string;
  email: string;
  clienteId: number;
  idPersona: number;
  idUsuario: number;
  idSector: number;
  avatar: string;
};

type ContextType = {
  user: AuthUser | null;
  setUser: React.Dispatch<React.SetStateAction<AuthUser | null>>;
  isLogin: boolean;
  setIsLogin: React.Dispatch<React.SetStateAction<boolean>>;
  token: string | null;
  setToken: React.Dispatch<React.SetStateAction<string | null>>;
  cerrarSession: () => void;
  guardarDatos: (user: contextUserInterface) => void;
  guardarPermisos: (permisos: Permiso[]) => void;
  permisos: Permiso[] | null;
};

export type GlobalContextProviderProps = {
  children: React.ReactNode;
};

export const AppContext = createContext<ContextType | null>(null);

export const AppProvider = ({ children }: GlobalContextProviderProps) => {
  const [user, setUser] = useState<AuthUser | null>(null);
  const [isLogin, setIsLogin] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const [permisos, setPermisos] = useState<Permiso[] | null>(null);

  const guardarPermisos = (permisos: Permiso[]) => {
    permisos && setPermisos(permisos);
  };

  const guardarDatos = (user: contextUserInterface) => {
    setUser(user);
    setIsLogin(true);
    setToken(sessionStorage.getItem("token"));
  };

  const cerrarSession = () => {
    postLogout();

    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("expiredToken");

    setUser(null);
    setToken(null);
  };

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        isLogin,
        setIsLogin,
        token,
        setToken,
        cerrarSession,
        guardarDatos,
        guardarPermisos,
        permisos,
      }}>
      {children}
    </AppContext.Provider>
  );
};
