import {
  Box,
  Pagination,
  PaginationItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState, useContext, useCallback } from "react";
import ShortLine from "../../components/ShortLine/ShortLine";
import useLanguage from "../../common/hooks/useLanguage";
import ES from "../../common/language/mismovimientos/es.json";
import EN from "../../common/language/mismovimientos/en.json";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { GetMovimientosByPersonaDataTable } from "../../services/MisMovimientosService";
import { misMovimientosInterface } from "../../common/interfaces/MisMovimientosInterface";
import "./MisMovimientos.css";
import { modalError } from "../../utils/validaciones";
import { LanguageContext } from "../../context/LanguageContext";
import { ThemeContext } from "../../context/ThemeContext";

const MisMovimientos = () => {
  const [misMovimientos, setMisMovimientos] = useState<
    misMovimientosInterface[] | null
  >(null);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [orden, setOrden] = useState<{ campo: string; ascendente: boolean }>({
    campo: "fecha",
    ascendente: false,
  });
  const language = useContext(LanguageContext);
  const { dictionary } = useLanguage({ EN, ES });
  const theme = React.useContext(ThemeContext);

  const fetchData = useCallback(
    (pageNumber = page, ordenamiento = orden) => {
      setLoading(true);
      const direccion = ordenamiento.ascendente ? "asc" : "desc";
      const query = `${ordenamiento.campo} ${direccion}`;

      GetMovimientosByPersonaDataTable(language.language, pageNumber, 10, query)
        .then((response) => {
          setMisMovimientos(response.data);
          setTotalPages(response.cantidadPaginas);
        })
        .catch((err) => {
          modalError("Se ha producido un error");
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [language.language, orden, page]
  );

  useEffect(() => {
    fetchData(page, orden);
  }, [page, orden, fetchData]);

  const cambiarOrdenamiento = (campo: string) => {
    setOrden((ordenActual) => {
      const esAscendente =
        ordenActual.campo === campo ? !ordenActual.ascendente : true;
      return { campo, ascendente: esAscendente };
    });
    fetchData(page, { campo, ascendente: !orden.ascendente });
  };

  const handleChangePag = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const handleSearch = (e: any) => {
    let value = e.target.value;
    misMovimientos?.filter(
      (el) =>
        el.fechaCorta.includes(value) ||
        el.tipoMovimientoDescripcion.includes(value) ||
        el?.estado?.includes(value) ||
        el.productosDescripcion?.includes(value)
    );
  };

  return (
    <div className="mis-movimientos-page">
      <Typography variant="h4" color="primary">
        {dictionary?.misMovimientos}
        <ShortLine position="left" />
      </Typography>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={3}
        border={2}
        borderColor={"primary.main"}
        borderRadius={3}
        className="mis-mov-table-container">
        <div>
          <Typography variant="h6" color="initial">
            {dictionary?.buscar}
          </Typography>
          <input
            type="text"
            className="input-form input-buscar"
            onChange={(e) => handleSearch(e)}
          />
        </div>

        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#E9E9E9" }}>
                {[
                  { label: "#", campo: "idMovimiento" },
                  { label: dictionary?.tabla.fecha, campo: "fecha" },
                  {
                    label: dictionary?.tabla.tipoTransaccion,
                    campo: "tipoMovimientoDescripcion",
                  },
                  { label: dictionary?.tabla.estado, campo: "estado" },
                  {
                    label: dictionary?.tabla.detalle,
                    campo: "productosDescripcion",
                  },
                  { label: dictionary?.tabla.puntos, campo: "puntos" },
                ].map((columna) => (
                  <TableCell
                    align="center"
                    key={`header-${columna.campo}`}
                    sx={{
                      fontWeight: "bold",
                      position: "relative",
                      cursor: "pointer",
                    }}
                    onClick={() => cambiarOrdenamiento(columna.campo)}>
                    <div className="row-header-reco">
                      {columna.label}
                      {orden.campo === columna.campo ? (
                        orden.ascendente ? (
                          <ArrowDropUpIcon />
                        ) : (
                          <ArrowDropDownIcon />
                        )
                      ) : (
                        <ImportExportIcon />
                      )}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={6} style={{ textAlign: "center" }}>
                    <CircularProgress />
                    <div>{dictionary?.cargando}</div>
                  </TableCell>
                </TableRow>
              ) : misMovimientos && misMovimientos.length > 0 ? (
                misMovimientos.map((row, index) => (
                  <TableRow
                    key={"body-" + row.idMovimiento + index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell align="center" component="th" scope="row">
                      {row.idMovimiento}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.fechaCorta}
                    </TableCell>
                    <TableCell align="center">
                      {row.tipoMovimientoDescripcion}
                    </TableCell>
                    <TableCell align="center">
                      <div className="mis-mov-estado-container">
                        <div
                          className={`mis-mov-estado ${
                            row.estado === "Pendiente" &&
                            "mis-mov-estado-pendiente"
                          }`}>
                          {row.estado}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      {row.productosDescripcion}
                    </TableCell>
                    <TableCell align="center">{row.puntos}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableCell align="center" colSpan={6}>
                  {dictionary?.sinResultados}
                </TableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}>
          <Stack spacing={2}>
            <Pagination
              color="primary"
              shape="rounded"
              variant="outlined"
              page={page}
              count={totalPages}
              onChange={handleChangePag}
              renderItem={(item) => (
                <PaginationItem
                  slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                  {...item}
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor:
                        theme?.theme && theme?.theme[10]
                          ? theme?.theme[10].color
                          : "primary", // Color de fondo para el número de página seleccionado
                      color: "white", // Color del texto para el número de página seleccionado
                    },
                  }}
                />
              )}
            />
          </Stack>
        </Box>
      </Box>
      <Box marginBottom={25}></Box>
    </div>
  );
};

export default MisMovimientos;
