import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const CustomSearch = ({ handleSearch }) => {
  return (
    <TextField
      type="search"
      className="input-form input-buscar"
      onChange={(e) => handleSearch(e.target.value)}
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};
export default CustomSearch;
