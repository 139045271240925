import { fetchContent, fetchNoJsonRes } from "../utils/fetchUtils";
import { cliente, headerOptions } from "../common/constants/constants";
import {
  ReconocimientoInterface,
  consumidorListInterface,
} from "../common/interfaces/ReconocimientoInterface";
import { modalError } from "../utils/validaciones";
import { handleServiceError } from "./GiftCardService";

export const GetConsumidoresForListMultiple = async (
  idCliente: number,
  idUsuario: number | undefined
) => {
  if (!idUsuario) {
    const user: string | null = sessionStorage.getItem("user");
    if (user) {
      idUsuario = JSON.parse(user).idUsuario;
    }
  }

  try {
    let response = await fetchContent(
      `/api/usuario/cliente/${idCliente}/consumidor_list_component`,
      {
        method: "GET",
      }
    );

    response = response.item2.filter(
      (el: consumidorListInterface) => el.idUsuario !== idUsuario
    );
    response.sort(function (
      a: consumidorListInterface,
      b: consumidorListInterface
    ) {
      if (a.apellido < b.apellido) {
        return -1;
      }
      if (a.apellido > b.apellido) {
        return 1;
      }
      return 0;
    });
    return response;
  } catch (error: any) {
    console.log(
      "[Reconocimiento], GetConsumidoresForListMultiple error]: " + error
    );
    throw handleServiceError(error);
  }
};

export const GetTipoReconocimientoBySector = (
  idSector: number | undefined,
  lang: string
) => {
  try {
    if (!idSector) {
      const user: string | null = sessionStorage.getItem("user");
      if (user) {
        idSector = JSON.parse(user).idSector;
      }
    }
    const response = fetchContent(
      `/api/tipoReconocimiento/sector/${idSector}?idioma=${lang}`,
      {
        method: "GET",
      }
    );
    return response;
  } catch (error: any) {
    throw new Error(
      "[Reconocimiento], GetTipoReconocimientoBySector error]: " + error
    );
  }
};

export const GetUsuarioEmail = (email: string) => {
  try {
    let response = fetchContent(`/api/usuario/emailcompleto/${email}`, {
      method: "GET",
    });

    return response;
  } catch (error: any) {
    console.error("GetUsuarioEmail" + error);

    throw new Error(
      "[Reconocimiento], GetTipoReconocimientoBySector error]: " + error
    );
  }
};

export const postNotificacionReconocimiento = (
  reconocimiento: ReconocimientoInterface
) => {
  try {
    let response = fetchNoJsonRes(`/api/notificacion/reconocimiento`, {
      method: "POST",
      body: JSON.stringify(reconocimiento),
      headers: headerOptions,
    });
    return response;
  } catch (error: any) {
    throw new Error(" postNotificacionReconocimiento: " + error);
  }
};

export const PostReconition = async (
  reconocimiento: ReconocimientoInterface,
  idUsuario: number | undefined
) => {
  try {
    if (
      reconocimiento.usuariosReconocidos &&
      reconocimiento.usuariosReconocidos?.length > 0
    ) {
      const idUsuariosReconocidos = reconocimiento.usuariosReconocidos?.map(
        (el) => {
          return { idUsuario: el };
        }
      );

      const recoBody = {
        idCliente: cliente,
        IdUsuarioAsignador: idUsuario,
        Descripcion: reconocimiento.descripcion,
        UsuariosReconocidos: idUsuariosReconocidos,
        IdTipoReconocimiento: reconocimiento.idTipoReconocimiento,
      };

      const response = await fetchNoJsonRes(`/api/reconocimiento`, {
        method: "POST",
        headers: {
          ...headerOptions,
          "x-api-version": "2.0",
        },
        body: JSON.stringify(recoBody),
      });

      if (!response.ok) {
        const errorData = await response.json();
        let errorMessages = [];

        for (const key in errorData.errors) {
          if (Object.prototype.hasOwnProperty.call(errorData.errors, key)) {
            errorMessages = errorMessages.concat(errorData.errors[key]);
          }
        }

        const formattedErrorMessages = errorMessages.join(" \n\n ");
        throw new Error(formattedErrorMessages);
      }

      return response;
    }
  } catch (error: any) {
    const errorMessage = error.message || "Se produjo un error desconocido.";
    if (error.status === 422) {
      modalError(errorMessage, 8000);
    } else {
      modalError(errorMessage);
    }
    throw new Error(errorMessage);
  }
};
