import { useContext, useState, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { AppContext } from '../../context/AppContext';
import useLanguage from './useLanguage';
import ES from '../../common/language/reconocimiento/es.json';
import EN from '../../common/language/reconocimiento/en.json';
import {
  GetReconocimientoBySupervisor,
  putReconocimientoAprobar,
} from '../../services/ReaconocimientoAprobacionService';
import {
  myReconocimientoInterface,
  ReconocimientoInterface,
} from '../../common/interfaces/ReconocimientoInterface';
import { SearchTable, notificacion } from '../../utils/functions';
import {
  modalError,
  validarUsuariosSeleccionados,
} from '../../utils/validaciones';
import { cliente } from '../../common/constants/constants';
import useCustomQuery from './useCustomQuery';

const useAprobarReconocimiento = () => {
  const [recoSearch, setRecoSearch] = useState<
    myReconocimientoInterface[] | null
  >(null);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [orden, setOrden] = useState({ campo: 'fecha', ascendente: true });
  const [form, setForm] = useState<ReconocimientoInterface>({ aprobados: [] });
  const appContext = useContext(AppContext);
  const { dictionary } = useLanguage({ EN, ES });
  const queryClient = useQueryClient();

  const fetchReconocimientos = async () => {
    const direccion = orden.ascendente ? 'asc' : 'desc';
    const query = `${orden.campo} ${direccion}`;

    return await GetReconocimientoBySupervisor(
      appContext?.user?.idUsuario,
      page,
      query,
      10
    );
  };

  const {
    useMyQuery: {
      isLoading: loading,
      isFetching: fetching,
      data: aproRealizados,
      refetch,
    },
  } = useCustomQuery({
    queryKey: [
      'aproRealizados',
      appContext?.user?.idUsuario,
      page,
      // orden.campo,
      // orden.ascendente,
    ],
    keepPreviousData: true,
    queryFn: async () => await fetchReconocimientos(),
    enabled: !!appContext?.user?.idUsuario, //> !! = null || undefined
    onSuccess: (data) => {
      setTotalPages(data.cantidadPaginas);
      setRecoSearch(data.data);
    },
    onError: (error: any) => {
      const errorMessage =
        error instanceof Error
          ? error.message
          : 'Error al traer los Reconocimientos';
      modalError('Error al traer los Reconocimientos\n' + errorMessage);
    },
  });

  const mutation = useMutation({
    mutationFn: () =>
      putReconocimientoAprobar(
        form,
        cliente,
        appContext?.user?.idPersona,
        appContext?.user?.idUsuario
      ),
    onMutate: () => {
      queryClient.setQueriesData<myReconocimientoInterface[]>(
        ['aproRealizados', appContext?.user?.idUsuario],
        (oldData = []) => {
          if (!Array.isArray(oldData)) {
            oldData = [];
          }
          return oldData.map((el) =>
            form.aprobados.includes(el.idReconocimiento)
              ? { ...el, estado: 'APROBADO' }
              : el
          );
        }
      );
    },
    onError: (error) => {
      notificacion(false, dictionary?.errorAlAprobar);
    },
    onSuccess: () => {
      notificacion(true, dictionary?.seAprobaronLosItems);
      setForm({ aprobados: [] });
    },
    onSettled: () => {
      queryClient.invalidateQueries([
        'aproRealizados',
        appContext?.user?.idUsuario,
      ]);
    },
  });
  
  const handleSearch = (value: string) => {
    const filter = SearchTable(
      aproRealizados,
      [
        'tituloTipoReconocimiento',
        'nombreUsuarioReconocido',
        'nombreUsuarioSupervisor',
        'puntos',
        'estado',
      ],
      value
    );
    setRecoSearch(filter);
  };

  const handleSubmit = async () => {
    if (validarUsuariosSeleccionados(form.aprobados)) {
      mutation.mutate();
    } else {
      notificacion(false, dictionary?.errorValidacion);
    }
  };

  useEffect(() => {
    refetch()
  }, [orden]);

  return {
    recoSearch,
    setRecoSearch,
    loading: loading || fetching,
    dictionary,
    handleSearch,
    form,
    setForm,
    handleSubmit,
    setPage,
    page,
    totalPages,
    setOrden,
    orden,
  };
};

export default useAprobarReconocimiento;
