import React, { useContext } from "react";
import Typography from "@mui/material/Typography";
import "./CatalogoCard.css";
import notFound from "../../../../common/assets/images/giftcards/icon-not-found.png";
import { AppContext } from "../../../../context/AppContext";
import { IProducto } from "../../../../common/interfaces/GiftCardInterfaces";
import { useNavigate } from "react-router-dom";
import ES from "../../../../common/language/catalogo/es.json";
import EN from "../../../../common/language/catalogo/en.json";
import useLanguage from "../../../../common/hooks/useLanguage";
import { Box } from "@mui/material";
import CustomOffer from "../../../../components/CustomIcons/CustomOffer";
type Props = {
  info: IProducto;
};

const CatalogoCard: React.FC<Props> = ({ info }) => {
  const navigate = useNavigate();
  const { dictionary } = useLanguage({ EN, ES });

  const handleClick = (id: number | undefined) => {
    navigate(`/producto/${id}`, { state: info });
  };
  const appContext = useContext(AppContext);

  return (
    <Box
      className="catalogo-card"
      onClick={() => handleClick(info?.idProducto)}
    >
      <Box className="catalogo-card-img-container">
        <img
          src={info.imagenUrl ? info.imagenUrl : notFound}
          alt={info.marcaDescripcion}
        />
        {info?.oferta ? (
          <CustomOffer offer={`${info?.porcentajeDescuento}%`} />
        ) : (
          ""
        )}
      </Box>
      <Box className="catalogo-card-info">
        <Typography
          variant="h6"
          color="primary"
          sx={{ fontSize: "19px", fontWeight: "bold" }}
        >
          {info.marcaDescripcion}
        </Typography>
        <Typography
          variant="subtitle1"
          color="primary"
          sx={{ fontSize: "15px", fontWeight: 400 }}
        >
          {info?.descripcion && info?.descripcion.length > 20
            ? info?.descripcion.slice(0, 45) + "..."
            : info?.descripcion}
        </Typography>
      </Box>
      <Box className="catalogo-bottom-card-container">
        {appContext?.user && info?.oferta ? (
          <Typography
            variant="h6"
            color="primary"
            sx={{
              fontSize: "12px",
              textDecoration: "line-through",
              color: "var(--color-light-gray)",
            }}
          >
            {dictionary?.desde} {info.puntosCanje} Pts
          </Typography>
        ) : (
          ""
        )}
        {appContext?.user && (
          <Typography
            variant="h6"
            color="primary"
            sx={{ fontSize: "14px", fontWeight: 700 }}
          >
            {dictionary?.desde} {info.puntosOferta ?? info.puntosCanje} Pts
          </Typography>
        )}
        <Typography
          variant={"subtitle1"}
          color={`var(--color-light-gray)`}
          fontWeight={400}
          fontSize={
            info.categoria && info.categoria?.length > 30 ? "13px" : "15px"
          }
        >
          {info.categoria}
        </Typography>
      </Box>
    </Box>
  );
};

export default CatalogoCard;
