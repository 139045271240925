import {
  Box,
  Button,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import React, { useEffect } from "react";
import customCountStyles from "./customCount.styles";
import { IProducto } from "../../../../common/interfaces/ProductoInterface";

type Props = {
  minPrice: number;
  maxPrice: number;
  amount: number;
  setAmount: React.Dispatch<React.SetStateAction<number>>;
  calculatePoints: (idProduct: number | string, amount: number) => void;
  error: boolean;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  exchange: string;
  product: IProducto;
};

const CustomCount: React.FC<Props> = ({
  maxPrice,
  minPrice,
  amount,
  setAmount,
  calculatePoints,
  error,
  setError,
  exchange,
  product,
}) => {
  useEffect(() => {
    if (amount < minPrice || amount > maxPrice) {
      setError(true);
    } else {
      setError(false);
    }
  }, [amount, maxPrice, minPrice, setError]);

  const styles = customCountStyles();

  return (
    <>
      {maxPrice && (
        <Box sx={styles.container}>
          <Box sx={styles.errorMessage}>
            {error && (
              <Typography color="red" fontSize={12}>
                El valor es{" "}
                {amount > maxPrice ? "mayor al máximo" : "menor al mínimo"}
              </Typography>
            )}
          </Box>
          <Box sx={styles.containerButtons}>
            <Button
              variant="contained"
              disableElevation
              size="small"
              disabled={amount <= minPrice}
              color="btnPrimary"
              onClick={() => {
                setAmount(+amount - (product.deltaIncrementoPrecio || 1));
                calculatePoints(
                  product.idProducto,
                  +amount - (product.deltaIncrementoPrecio || 1)
                );
              }}
              style={{ padding: 0 }}
              sx={{ ...styles.buttonLeft, ...styles.button }}
              children={<ArrowBackIosRoundedIcon sx={{ fontSize: "20px" }} />}
            />
            <OutlinedInput
              sx={styles.input}
              value={amount ?? minPrice}
              placeholder={minPrice.toString()}
              startAdornment={
                <InputAdornment position="start">{exchange}</InputAdornment>
              }
              inputMode="numeric"
              onChange={(e) => {
                calculatePoints(product.idProducto, +e.target.value);
                setAmount(+e.target.value);
              }}
            />
            <Button
              variant="contained"
              disableElevation
              size="small"
              disabled={amount >= maxPrice}
              color="btnPrimary"
              onClick={() => {
                setAmount(+amount + (product.deltaIncrementoPrecio || 1));
                calculatePoints(
                  product.idProducto,
                  +amount + (product.deltaIncrementoPrecio || 1)
                );
              }}
              sx={{ ...styles.buttonRight, ...styles.button }}
              children={
                <ArrowForwardIosRoundedIcon sx={{ fontSize: "20px" }} />
              }
            />
          </Box>
          <Box sx={styles.subtitle}>
            <Typography color="#adadad" fontSize={12}>
              Min. {exchange} {minPrice}
            </Typography>
            <Typography color="#adadad" fontSize={12}>
              Max. {exchange} {maxPrice}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};
export default CustomCount;
