import { Button, CircularProgress } from "@mui/material";
import { useState } from "react";

type IProps = {
  children: React.ReactNode;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  onClick: () => void;
  disabled?: boolean;
};

const ButtonSubmit: React.FC<IProps> = ({
  children,
  color,
  onClick,
  disabled = false,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      onClick();
    } catch {
      console.error("Error en submit");
    } finally {
      setLoading(false);
    }
  };
  return (
    <Button
      disabled={loading || disabled}
      variant="contained"
      color={disabled ? "warning" : color ? color : "primary"}
      onClick={handleSubmit}
      sx={{ minWidth: 150 }}>
      {loading ? <CircularProgress size={20} /> : children}
    </Button>
  );
};

export default ButtonSubmit;
