import Swal from "sweetalert2";
import { formularioContactoInterface } from "../common/interfaces/ContactInterface";
import { contactFormService } from "../services/ContactService";
import { cryptoValorInterface } from "../common/interfaces/CryptoInterface";
import { dataExchangeInterface } from "../common/interfaces/CompraCryptoInterface";
import { formMisDatosInterface } from "../common/interfaces/MisDatosInterface";
import { PutPersona } from "../services/MisDatosService";
import { fetchContent } from "./fetchUtils";

// Get theme styles by client
export const getTheme = async (idCliente: number) => {
  try {
    const request = await fetchContent(
      `/api/cliente/${idCliente}/colorestilos`
    );
    const data = request;
    const result = await data.sort((a: any, b: any) => a.orden - b.orden);
    return result;
  } catch (err) {
    console.error(err);
  }
};

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export const formValidation = async (
  form: formularioContactoInterface,
  dictionary: any
) => {
  let val = true;
  let campoErr: string = "";
  for (let campo in form) {
    if (typeof form[campo as keyof typeof form] === "string") {
      if (form[campo as keyof typeof form].toString().trim().length < 1) {
        val = false;
        campoErr = campo;
        break;
      }
    }
  }
  let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
  if (!form.email.trim()) {
    campoErr = "email";
    val = false;
  } else if (!emailRegex.test(form.email)) {
    campoErr = "emailFormato";
    val = false;
  }

  if (form.idMotivoContacto === -1) {
    campoErr = "motivo";
    val = false;
  }

  if (val) {
    await contactFormService(form).then(() =>
      Toast.fire({
        icon: "success",
        title: dictionary?.form.consultaExitosa,
      })
    );
  } else {
    Toast.fire({
      icon: "error",
      title: dictionary?.form.validaciones[campoErr as keyof typeof dictionary]
        ? dictionary?.form.validaciones[campoErr as keyof typeof dictionary]
        : dictionary?.form.validaciones.camposRequeridos,
    });
  }

  return val;
};

export const modificarFecha = (fecha: string) => {
  return fecha.slice(5, 10);
};

export const dataGrapfFormat = (res: cryptoValorInterface[]) => {
  const data = {
    labels: res.map((el) => el.timestamp),
    datasets: [
      {
        data: res.map((el) => el.avg),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  return data;
};

export const cambiarPuntosPorCripto = (
  data: number,
  dataExchange: dataExchangeInterface | null,
  moneda: string
) => {
  const valorCrypto: any = dataExchange?.quotes.filter(
    (el) => el.currency === moneda
  );
  return parseFloat((data / valorCrypto[0].ask).toFixed(8));
};

export const evaluarPuntosCriticos = (event: any, puntosPersona: number) => {
  if (event.target.value < 0 || event.target.value === "") {
    return true;
  } else {
    return false;
  }
};

export const evaluarCamposCompraCrypto = (
  puntos: number,
  text: string = ""
) => {
  if (puntos <= 0 || puntos === undefined) {
    Toast.fire({
      icon: "error",
      title: text,
    });
    return false;
  }
  return true;
};

// funciones de vender criptos

export const cambiarCriptoPorImporte = (
  data: number,
  dataExchange: dataExchangeInterface | null,
  moneda: string
) => {
  const valorCrypto: any = dataExchange?.quotes.filter(
    (el) => el.currency === moneda
  );
  return parseFloat((data * valorCrypto[0].ask).toFixed(8));
};

export const notificacionItemAgregado = (msj: string) => {
  Toast.fire({
    icon: "success",
    title: msj,
  });
};

export const notificacion = (estado: boolean, msj: string) => {
  Toast.fire({
    icon: estado ? "success" : "error",
    title: msj,
  });
};

export const submitMiContacto = (
  form: formMisDatosInterface | null,
  idPersona: number | null
) => {
  if (typeof form?.idGeneroPersona === "string") {
    form.idGeneroPersona = parseInt(form?.idGeneroPersona);
  }
  if (
    !form?.cp ||
    !form?.domicilio ||
    !form?.idPais ||
    !form?.idLocalidad ||
    !form?.idProvincia
  ) {
    Toast.fire({
      icon: "error",
      title: `Faltan campos requeridos marcados con *`,
    });
  } else {
    form.idPersona = idPersona!;

    PutPersona(form, idPersona).then((data) => {
      Toast.fire({
        icon: "success",
        title: "Actualizacion exitosa",
      });
    });
  }
};

export const PutChangePassModal = () => {
  Toast.fire({
    icon: "success",
    title: "Actualizacion exitosa",
  });
};

export const SearchTable = (
  dataRows: any[] | null,
  columns: string[],
  searchString: string
) => {
  if (dataRows && dataRows.length > 0) {
    const filter = dataRows?.filter((el) => {
      let estado = false;
      columns.forEach((key) => {
        const value = el[key as keyof typeof el];
        const actualValue = value?.value || value;
        if (
          typeof actualValue === "string" &&
          actualValue
            .toLocaleLowerCase()
            .includes(searchString.toLocaleLowerCase())
        ) {
          estado = true;
        } else if (
          typeof actualValue === "number" &&
          actualValue
            .toString()
            .toLocaleLowerCase()
            .includes(searchString.toLocaleLowerCase())
        ) {
          estado = true;
        }
      });
      return estado;
    });

    return filter;
  }
};

export const changeDateFormat = (date: Date) => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};
