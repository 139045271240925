import { fetchContent, fetchNoJsonRes } from "../utils/fetchUtils";
import { handleServiceError } from "./GiftCardService";

export const GetMovimientosByPersonaDataTable = (
    lang: string,
    page: number = 1,
    pageSize: number = 10,
    ordenamiento: string = 'fecha desc'
) => {
    try {
        let response = fetchContent(`/api/movimiento/persona/consolidado?idioma=${lang}&ordenamiento=${ordenamiento}&pagina=${page}&CantidadRegistrosPorPagina=${pageSize}`,
            {
                method: "GET"
            }
        )
        return response;
    } catch (error: any) {
        console.log("[GetMovimientosByPersonaDataTable error]: " + error);
        throw handleServiceError(error);
    }
}


export const GetMovimientosByPersonaDataTableHome = async (lang: string) => {
    try {
        const mov_res = await fetchNoJsonRes(`/api/movimiento/persona/consolidado/home?idioma=${lang}`,
            {
                method: "GET",
            }
        )
        if (mov_res.ok && mov_res.status !== 204) {
            const mov = await mov_res.json();
            return mov
        }
        return null;
    } catch (error: any) {

        throw new Error("[GetMovimientosByPersonaDataTableHome error]: " + error);
    }
}