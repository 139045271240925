import { useContext, useEffect, useState } from "react";
import useLanguage from "./useLanguage";
import { Usuario } from "../../common/interfaces/ComunInterface";
import { useQuery } from "react-query";
import ES from "../../common/language/reconocimiento/es.json";
import EN from "../../common/language/reconocimiento/en.json";
import { GetMiEquipo } from "../../services/MiEquipoService";
import { AppContext } from "../../context/AppContext";
import { modalError } from "../../utils/validaciones";

const useMiEquipo = () => {
  const [usuSearch, setUsuSearch] = useState<Usuario[] | null>(null);
  const appContext = useContext(AppContext);
  const { dictionary } = useLanguage({ EN, ES });
  const { isLoading: loading, data: usuarios } = useQuery({
    queryKey: ["usuarios", appContext?.user?.idUsuario],
    queryFn: async () => GetMiEquipo(),
    onError: (error: any) => {
      const errorMessage =
        error instanceof Error ? error.message : "Error al traer Mi Equipo";
      modalError("Error al traer Mi Equipo\n" + errorMessage);
    },
  });

  useEffect(() => {
    usuarios && setUsuSearch(usuarios);
  }, [usuarios]);

  const handleSearch = (e: any) => {
    let value = e.target.value;
    let filter = usuarios?.filter(
      (el: Usuario) =>
        el?.nombre?.includes(value) || el?.apellido?.includes(value)
    );
    setUsuSearch(filter ? filter : usuarios);
  };
  return {
    usuarios,
    usuSearch,
    setUsuSearch,
    loading,
    dictionary,
    handleSearch,
  };
};

export default useMiEquipo;
