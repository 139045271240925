import { useState } from "react";
import {
  IDetalleProducto,
  IProducto,
} from "../../../../common/interfaces/ProductoInterface";
import { Box, Button } from "@mui/material";
import fichasPrecioContainerStyles from "./fichasPrecioContainer.styles";

type Props = {
  detalleProd: IDetalleProducto;
  setProducto: React.Dispatch<React.SetStateAction<any>>;
  calcularPuntos: (idProduct: number | string, importe: number) => void;
  setImporte: React.Dispatch<React.SetStateAction<any>>;
};
const FichasPrecioContainer: React.FC<Props> = ({
  detalleProd,
  setProducto,
  calcularPuntos,
  setImporte,
}) => {
  const [fichaSel, setFichaSel] = useState<number | undefined>(undefined);

  const styles = fichasPrecioContainerStyles();

  const handleFicha = (producto: IProducto) => {
      setFichaSel(producto.idProducto);
      setImporte(producto.precio);
      setProducto(producto);
      calcularPuntos(producto.idProducto, producto.precio);
  };
  
  return (
    <Box sx={styles.container}>
      {detalleProd.productos &&
        detalleProd.productos.length > 1 &&
        detalleProd.productos
          .sort(function (a, b) {
            return a.precio - b.precio;
          })
          .map((el: IProducto) => (
            <Button
              variant={fichaSel === el.idProducto ? "contained" : "outlined"}
              key={`precio-${el.idProducto}`}
              className={`ficha-precio ${
                fichaSel === el.precio && "ficha-precio--active"
              }`}
              onClick={() => handleFicha(el)}
            >
              ${el.precio}
            </Button>
          ))}
    </Box>
  );
};

export default FichasPrecioContainer;
