import { useContext } from "react";
import { Grid, Typography } from "@mui/material";
import DashboardBox from "./components/DashboardBox/DashboardBox";
import "./styles/index.css";
import ShortLine from "../../../../components/ShortLine/ShortLine";
import { AppContext } from "../../../../context/AppContext";
import { PERMISOS_CODIGOS } from "../../../../common/constants/constants";
import ProgressBar from "./components/ProgressBar/ProgressBar";
import { MisReconocimientosTable } from "./components/MisReconocimientoTable/MisReconocimientosTable";
import UltimosConsumosTable from "./components/UltimosConsumosTable/UltimosConsumosTable";
import CategoriaProgressBar from "./components/CategoriaProgressBar/CategoriaProgressBar";
import ES from "../../../../common/language/home/es.json";
import EN from "../../../../common/language/home/en.json";
import useLanguage from "../../../../common/hooks/useLanguage";
import useHome from "../../../../common/hooks/useHome";
import MasReconocidosTable from "./components/MasReconocidosTable/MasReconocidosTable";

const Dashboard = () => {
  const appContext = useContext(AppContext);

  const { dictionary } = useLanguage({ EN, ES });
  const { puntos, crypto, edu } = useHome();

  return (
    <section className="dashboard">
      <div className="dashboard-titulo-container">
        <Typography variant="h4" color="primary">
          {dictionary?.dashboard}
          <ShortLine position="left" />
        </Typography>
      </div>

      <section className="DashboardContainer">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <DashboardBox
              title={dictionary?.puntosDisponibles}
              ruta="/misMovimientos">
              <>
                <Typography variant="h4" color="white">
                  {puntos}
                </Typography>
                <Typography variant="h6" color="white">
                  total
                </Typography>
              </>
            </DashboardBox>
          </Grid>
          {/* CRIPTO */}
          {appContext?.permisos &&
            appContext?.permisos?.filter(
              (el) => el.codigo === PERMISOS_CODIGOS.criptomonedas
            ).length > 0 && (
              <Grid item xs={12} sm={12} md={4}>
                <DashboardBox title={dictionary?.puntosEnCripto} ruta="/crypto">
                  <>
                    <Typography variant="h4" color="white">
                      {crypto}
                    </Typography>
                    <Typography variant="h6" color="white">
                      total
                    </Typography>
                  </>
                </DashboardBox>
              </Grid>
            )}
          {/* Educacion */}
          {appContext?.permisos &&
            appContext?.permisos?.filter(
              (el) => el.codigo === PERMISOS_CODIGOS.criptomonedas
            ).length > 0 && (
              <Grid item xs={12} sm={12} md={4}>
                <DashboardBox
                  title={dictionary?.nivelEducacion}
                  ruta="/educacion">
                  <ProgressBar edu={edu} />
                </DashboardBox>
              </Grid>
            )}
          {/* Giftcards mas consumidas */}
          <Grid
            item
            xs={12}
            sm={12}
            md={
              appContext?.permisos &&
              appContext?.permisos?.filter(
                (el) => el.codigo === PERMISOS_CODIGOS.criptomonedas
              ).length > 0
                ? 4
                : 8
            }>
            <DashboardBox
              title={dictionary?.giftCardsMasConsumidas}
              ruta="/catalogo"
              color="white">
              <CategoriaProgressBar />
            </DashboardBox>
          </Grid>
          {/* ultimos movimientos */}
          <Grid
            item
            xs={12}
            sm={12}
            md={
              appContext?.permisos &&
              appContext?.permisos?.filter(
                (el) => el.codigo === PERMISOS_CODIGOS.criptomonedas
              ).length > 0
                ? 8
                : 12
            }>
            <DashboardBox
              title={dictionary?.ultimosConsumos}
              color="white"
              ruta="/misMovimientos">
              <UltimosConsumosTable />
            </DashboardBox>
          </Grid>
          {/* reconocimientos */}
          {appContext?.permisos?.find(
            (el) => el.codigo === PERMISOS_CODIGOS.reconocimientosEnDashboardWeb
          ) && (
            <>
              <Grid item xs={12} sm={12} md={6}>
                <DashboardBox title={dictionary?.masReconocidos} color="white">
                  <MasReconocidosTable />
                </DashboardBox>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <DashboardBox
                  title={dictionary?.ultimosReconocimientos}
                  color="white"
                  ruta="/reconocimiento/realizados">
                  <MisReconocimientosTable />
                </DashboardBox>
              </Grid>
            </>
          )}
        </Grid>
      </section>
    </section>
  );
};

export default Dashboard;
