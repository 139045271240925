import React, { SetStateAction, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { Box, Pagination, PaginationItem, Stack } from "@mui/material";
import { Usuario } from "../../../../common/interfaces/ComunInterface";
import ES from "../../../../common/language/reconocimiento/es.json";
import EN from "../../../../common/language/reconocimiento/en.json";
import useLanguage from "../../../../common/hooks/useLanguage";

type Props = {
  usuarios: Usuario[] | null;
  setUsuarios: React.Dispatch<SetStateAction<any>>;
  loading: boolean;
};

const MiEquipoTable = ({ usuarios, setUsuarios, loading }: Props) => {
  const [data, setData] = useState<Usuario[] | null>(null);
  const [page, setPage] = useState<number>(1);

  const { dictionary } = useLanguage({ EN, ES });

  useEffect(() => {
    setData(usuarios);
  }, [usuarios]);

  const handleChangePag = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const invertirOrden = () => {
    let arrInvertido = usuarios ? [...usuarios].reverse() : [];
    setUsuarios(arrInvertido);
  };

  return (
    <Box marginTop={3}>
      <TableContainer>
        <Table sx={{ width: "100%" }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#E9E9E9" }}>
              {[
                dictionary?.formBusqueda.nombre,
                dictionary?.formBusqueda.apellido,
                dictionary?.formBusqueda.email,
              ].map((el) => (
                <TableCell
                  align="center"
                  key={`header-${el}`}
                  sx={{ fontWeight: "bold", position: "relative" }}>
                  <div className="row-header-reco">
                    {el}
                    <div onClick={() => invertirOrden()}>
                      <ImportExportIcon />
                    </div>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.length > 0 ? (
              data.slice((page - 1) * 10, page * 10).map((row, index) => (
                <TableRow
                  key={`mi-equipo-table-${row.idUsuario}-${index}`}
                  // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row.nombre}</TableCell>
                  <TableCell align="center">{row.apellido}</TableCell>
                  <TableCell align="center">{row.email}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableCell align="center" colSpan={3}>
                {loading
                  ? dictionary?.cargando
                  : dictionary?.datatable.sinResultados}
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}>
        <Stack spacing={2}>
          <Pagination
            color="primary"
            shape="rounded"
            variant="outlined"
            siblingCount={0}
            onChange={handleChangePag}
            count={data?.length ? Math.ceil(data?.length / 10) : 1}
            renderItem={(item) => (
              <PaginationItem
                slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                {...item}
              />
            )}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default MiEquipoTable;
