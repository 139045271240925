import { Box, Button, TextField, Typography } from "@mui/material";
import ShortLine from "../../components/ShortLine/ShortLine";
import MiEquipoTable from "./components/MiEquipoTable/MiEquipoTable";
import useMiEquipo from "../../common/hooks/useMiEquipo";
import { AppContext } from "../../context/AppContext";
import React from "react";
import { useQuery } from "react-query";
import { getBudgetSupervisor } from "../../services/MiEquipoService";

const MiEquipo = () => {
  const { usuSearch, setUsuSearch, loading, dictionary, handleSearch } =
    useMiEquipo();

  const appContext = React.useContext(AppContext);
  const idUsuario = appContext?.user?.idUsuario;

  const { data: budgetSupervisor } = useQuery({
    queryKey: ["budget-supervisor-key", idUsuario],
    queryFn: async () => await getBudgetSupervisor(idUsuario),
  });

  const formatNumber = (num: number) => {
    return new Intl.NumberFormat("es-ES").format(num);
  };
  return (
    <Box padding={"30px 20px"}>
      <Typography variant="h4" color="primary">
        {dictionary?.miEquipo}
      </Typography>

      <ShortLine position="left" />
      {idUsuario === budgetSupervisor?.idSupervisor ? (
        <Button variant="contained">
          <Typography variant="h6">
            Presupuesto: ${formatNumber(budgetSupervisor?.presupuesto) || 0}
          </Typography>
        </Button>
      ) : null}
      <section className="myReco-table-section">
        <Box
          border={2}
          borderColor={"primary.main"}
          borderRadius={3}
          className="reconocimientos-realizados-table-container"
          marginTop={2}
        >
          <div>
            <Typography variant="h6" color="initial">
              {dictionary?.formBusqueda.buscar}
            </Typography>
            <input
              type="text"
              className="input-form input-buscar"
              onChange={(e) => handleSearch(e)}
            />
          </div>
          <MiEquipoTable
            usuarios={usuSearch}
            setUsuarios={setUsuSearch}
            loading={loading}
          />
        </Box>
      </section>
    </Box>
  );
};

export default MiEquipo;
