import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import {
  Box,
  Paper,
  CircularProgress,
  Pagination,
  PaginationItem,
  Stack,
} from "@mui/material";
import { myReconocimientoInterface } from "../../../common/interfaces/ReconocimientoInterface";
import "./reconocimientos.styles.css";
import Swal from "sweetalert2";
import useLanguage from "../../../common/hooks/useLanguage";
import { formatDate } from "../../../utils/datesUtils";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ThemeContext } from "../../../context/ThemeContext";
import ES from "../../../common/language/reconocimiento/es.json";
import EN from "../../../common/language/reconocimiento/en.json";
import Chip from "@mui/material/Chip";
import { Typography } from "@mui/material";

type Props = {
  recoRealizados: myReconocimientoInterface[] | null;
  loading: boolean;
  totalPages: number;
  page: number;
  setPage: (page: number) => void;
  cambiarOrdenamiento: (campo: string) => void;
  orden: { campo: string; ascendente: boolean | null };
};

export const ReconocimientosRealizadosTable: React.FC<Props> = ({
  recoRealizados,
  loading,
  totalPages,
  page,
  setPage,
  cambiarOrdenamiento,
  orden,
}) => {
  const { dictionary } = useLanguage({ EN, ES });
  const theme = React.useContext(ThemeContext);

  const handleChangePag = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const verDetalle = (persona: string, descripcion: string, idioma: string, imagen: string) => {
    Swal.fire({
      position: "top",
      title: persona + (idioma === "ES" ? " comentario:" : "'s comment"),
      text: descripcion,
      imageUrl: imagen,
      showCancelButton: true,
      showConfirmButton: false,
      customClass: {
        popup: "myreco-swal-container",
        title: "myreco-swal-title",
        cancelButton: "myreco-btn",
      },
    });
  };

  return (
    <Box marginTop={3}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#E9E9E9" }}>
              {[
                {
                  label: "ID",
                  campo: "idReconocimiento",
                  hasOrdering: true
                },
                {
                  label: dictionary?.realizados.datatable.fecha,
                  campo: "fecha",
                  hasOrdering: true,
                },
                {
                  label: dictionary?.realizados.datatable.tipoReconocimiento,
                  campo: "tituloTipoReconocimiento",
                  hasOrdering: true,
                },
                {
                  label: dictionary?.realizados.datatable.empleadoReconocido,
                  campo: "nombreUsuarioReconocido",
                  hasOrdering: true,
                },
                {
                  label: dictionary?.realizados.datatable.supervisor,
                  campo: "nombreUsuarioSupervisor",
                  hasOrdering: true,
                },
                {
                  label: dictionary?.realizados.datatable.puntos,
                  campo: "puntos",
                  hasOrdering: true,
                },
                {
                  label: dictionary?.realizados.datatable.estado,
                  campo: "estado",
                  hasOrdering: true,
                },
                {
                  label: dictionary?.realizados.datatable.fechaAprobacion,
                  campo: "fechaAprobacion",
                  hasOrdering: true,
                },
                {
                  label: dictionary?.realizados.datatable.comentario,
                  campo: "descripcion",
                  hasOrdering: false,
                },
              ].map((columna) => (
                <TableCell
                  align="center"
                  key={`header-${columna.campo}`}
                  sx={{fontSize: 12, fontWeight: "bold", cursor: "pointer" }}
                  onClick={() =>
                    columna?.hasOrdering && cambiarOrdenamiento(columna.campo)
                  }>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}>
                    {columna.label}
                    {/* <Typography fontSize={12}>{columna.label}</Typography> */}
                    {columna?.hasOrdering ? (
                      orden.campo === columna.campo ? (
                        orden.ascendente ? (
                          <ArrowDropUpIcon fontSize="small" />
                        ) : (
                          <ArrowDropDownIcon fontSize="small" />
                        )
                      ) : (
                        <ImportExportIcon fontSize="small" />
                      )
                    ) : null}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={8} style={{ textAlign: "center" }}>
                  <CircularProgress />
                  <div>{dictionary?.cargando}</div>
                </TableCell>
              </TableRow>
            ) : recoRealizados && recoRealizados.length > 0 ? (
              recoRealizados.map((row, index) => (
                <TableRow key={`reco-realizado-${row.idMovimiento}-${index}`}>
                  <TableCell component="th" scope="row">
                    {row.idReconocimiento}
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(row.fecha)}
                  </TableCell>
                  <TableCell align="center">
                    {row.tituloTipoReconocimiento}
                  </TableCell>
                  <TableCell align="center">
                    {row.nombreUsuarioReconocido}
                  </TableCell>
                  <TableCell align="center">
                    {row.nombreUsuarioSupervisor}
                  </TableCell>
                  <TableCell align="center">
                    {row.puntos === 0 ? "-" : row.puntos}
                  </TableCell>
                  <TableCell align="center">
                    <Chip
                      color={row.estado === "APROBADO" ? "success" : "warning"}
                      label={
                        row.estado === "APROBADO"
                          ? dictionary?.datatable.aprobado
                          : dictionary?.datatable.pendienteAprobacion
                      }
                    />
                  </TableCell>
                  <TableCell align="center">
                  {!row.fechaAprobacionString || row.fechaAprobacionString.length === 0 ? "-" : row.fechaAprobacionString}
                  </TableCell>
                  <TableCell align="center">
                    <Chip
                      onClick={() =>
                        verDetalle(
                          row.nombreUsuarioAsignador,
                          row.descripcion,
                          dictionary?.idioma,
                          row.imagen
                        )
                      }
                      color="primary"
                      label={dictionary?.datatable.verDetalles}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableCell align="center" colSpan={8}>
                {dictionary?.datatable.sinResultados}
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: 3,
        }}>
        <Stack spacing={2}>
          <Pagination
            color="primary"
            shape="rounded"
            variant="outlined"
            page={page}
            count={totalPages}
            onChange={handleChangePag}
            renderItem={(item) => (
              <PaginationItem
                components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                {...item}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor:
                      theme?.theme && theme?.theme[10]
                        ? theme?.theme[10].color
                        : "primary", // Color de fondo para el número de página seleccionado
                    color: "white", // Color del texto para el número de página seleccionado
                  },
                }}
              />
            )}
          />
        </Stack>
      </Box>
    </Box>
  );
};
