import React, { SetStateAction, useContext } from "react";
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ES from "../../../common/language/shared/es.json";
import EN from "../../../common/language/shared/en.json";
import useLanguage from "../../../common/hooks/useLanguage";
import { AppContext } from "../../../context/AppContext";
import { MENU_DRAWER_LOGIN, RUTAS } from "../../../common/constants/rutas";

type Props = {
  setOpen: React.Dispatch<SetStateAction<boolean>>;
};

const MenuDrawerItemsLogin: React.FC<Props> = ({ setOpen }) => {
  const [openCollapse, setOpenCollapse] = React.useState(false);

  const { dictionary } = useLanguage({ EN, ES });
  const appContext = useContext(AppContext);

  const navigate = useNavigate();
  const handleCollapse = () => {
    setOpenCollapse(!openCollapse);
  };

  const handleClick = (ruta: string) => {
    if (ruta === RUTAS.copa) {
      window.open(ruta, "_blank");
    } else {
      navigate(ruta);
      setOpen(false);
    }
  };

  return (
    <>
      {MENU_DRAWER_LOGIN.filter((el) =>
        appContext?.permisos?.find((permiso) => permiso.codigo === el.codigo)
      ).map((el) =>
        !el.subMenu ? (
          <ListItem disablePadding divider={el.divider}>
            <ListItemButton
              onClick={() => handleClick(el.ruta)}
              sx={{ display: "flex", gap: "10px" }}>
              {el.icon}
              <ListItemText primary={dictionary?.header[el.dictionario]} />
            </ListItemButton>
          </ListItem>
        ) : (
          <>
            <ListItem disablePadding divider={false}>
              <ListItemButton
                onClick={handleCollapse}
                sx={{ display: "flex", gap: "10px" }}>
                {el.icon}
                <ListItemText primary={dictionary?.header.reconocimientos} />
                {openCollapse ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </ListItem>
            <Collapse in={openCollapse} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {el.subMenu
                  .filter((subItem) =>
                    appContext?.permisos?.find(
                      (subPermiso) => subPermiso.codigo === subItem.codigo
                    )
                  )
                  .map((el, index) => (
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => handleClick(el.ruta)}>
                      <ListItemText
                        primary={dictionary?.header[el.dictionario]}
                      />
                    </ListItemButton>
                  ))}
              </List>
              <Divider />
            </Collapse>
          </>
        )
      )}
    </>
  );
};

export default MenuDrawerItemsLogin;
