import { useNavigate, Outlet, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Loading from "../Loaders/Loading";
import { fetchContent, Method } from "../../utils/fetchUtils";

const PrivateRoute = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkTokenExpiry = () => {
      const expiredToken = JSON.parse(
        sessionStorage.getItem("expiredToken") || "null"
      );

      console.log("expiredToken:", expiredToken);
      const token = sessionStorage.getItem("token");

      if (expiredToken) {
        const expiryDate = new Date(expiredToken);
        const now = new Date();

        if (now > expiryDate) {
          // Token ha expirado
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("expiredToken");
          setIsValid(false);
          setIsLoading(false);
          navigate("/login?expiredToken=true");
          return;
        }
      }

      if (!token) {
        setIsValid(false);
        setIsLoading(false);
        navigate("/login");
        return;
      }

      validateToken();
    };

    const validateToken = async () => {
      const token = sessionStorage.getItem("token");

      try {
        await fetchContent("/api/Usuario/verifyToken", {
          method: Method.POST,
          headers: {
            "content-type": "application/json",
            authorization: `Bearer ${token}`,
            "X-Api-Version": 2.0,
          },
        });
        setIsValid(true);
      } catch (error) {
        console.error(error);
        setIsValid(false);
        navigate("/login?expiredToken=true");
      } finally {
        setIsLoading(false);
      }
    };

    checkTokenExpiry();
  }, [navigate]);

  if (isLoading) {
    return <Loading />;
  }
  if (!isValid) {
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
