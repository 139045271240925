import { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import { Box, Button } from "@mui/material";
import "./contacto.css";
import InputForm from "../../components/Inputs/InputForm/InputForm";
import {
  cliente,
  formularioEstructura,
} from "../../common/constants/constants";
import ShortLine from "../../components/ShortLine/ShortLine";
import useLanguage from "../../common/hooks/useLanguage";
import ES from "../../common/language/contactanos/es.json";
import EN from "../../common/language/contactanos/en.json";
import {
  formularioContactoInterface,
  motivoInterface,
} from "../../common/interfaces/ContactInterface";
import { motivoService } from "../../services/ContactService";
import { formValidation } from "../../utils/functions";
import { useNavigate } from "react-router-dom";
import { modalError } from "../../utils/validaciones";
import { LanguageContext } from "../../context/LanguageContext";

const Contacto = () => {
  const [form, setForm] =
    useState<formularioContactoInterface>(formularioEstructura);
  const [motivos, setMotivos] = useState<motivoInterface[] | null>(null);

  const { dictionary } = useLanguage({ EN, ES });
  const navigate = useNavigate();
  const language = useContext(LanguageContext);

  useEffect(() => {
    motivoService(language.language)
      .then((data) => setMotivos(data))
      .catch(() => modalError("No se logró traer los motivos"));
  }, [language.language]);

  const handleChange = (e: any) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let dateNow = new Date().toISOString();
    const updatedForm = { ...form, fecha: dateNow, idCliente: cliente };
    try {
      const isValid = await formValidation(updatedForm, dictionary);
      if (isValid) {
        navigate("/agradecimiento");
      }
    } catch (error: any) {
      modalError("Error");
    }
  };

  return (
    <div className="contacto-section">
      <Typography variant="h4" color="primary">
        {dictionary?.contactanos}
        <ShortLine position="left" />
      </Typography>
      <div className="form-msg-container">
        <Typography
          variant="h6"
          color="primary"
          textAlign="center"
          maxWidth="900px"
          width="90%">
          {dictionary?.invitacionContacto}
        </Typography>
        <ShortLine position="center" />
      </div>
      <form action="POST" onSubmit={submit}>
        <FormControl
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Box
            sx={{
              maxWidth: 800,
              width: "100%",
              flexDirection: "column",
              height: 550,
              padding: 5,
              borderRadius: 5,
              border: 2,
              borderColor: "primary.main",
              display: "flex",
              justifyContent: "center",
            }}>
            <div className="input-row">
              <InputForm
                label={dictionary?.form.nombre}
                type="text"
                name="nombre"
                form={form}
                setForm={setForm}
              />
              <InputForm
                label={dictionary?.form.email}
                type="email"
                name="email"
                form={form}
                setForm={setForm}
              />
            </div>
            <div className="input-container">
              <label htmlFor="select">{dictionary?.form.motivo}</label>
              <select
                name="idMotivoContacto"
                id="select"
                className="input-form"
                value={form.idMotivoContacto}
                onChange={(e) => handleChange(e)}>
                <option value={-1} disabled selected>
                  {dictionary?.form.seleccioneMotivo}
                </option>
                {motivos &&
                  motivos.map((motivo) => (
                    <option
                      key={`${motivo.idMotivoContacto}-motivo`}
                      value={motivo.idMotivoContacto}>
                      {motivo.descripcion}
                    </option>
                  ))}
              </select>
            </div>
            <InputForm
              label={dictionary?.form.asunto}
              type="text"
              name="asunto"
              form={form}
              setForm={setForm}
            />
            <div className="input-container">
              <label>{dictionary?.form.consulta}</label>
              <textarea
                name="descripcion"
                className="input-form txtarea-form"
                onChange={(e) => handleChange(e)}></textarea>
            </div>
          </Box>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ width: "300px" }}>
            {dictionary?.form.enviar}
          </Button>
        </FormControl>
      </form>
    </div>
  );
};

export default Contacto;
