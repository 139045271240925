import { Box, Button, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../common/redux/store";
import {
  decrement,
  increament,
  removeItem,
} from "../../../../common/redux/features/carritoReducer";
import "./CarritoItemContainer.css";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  disminuir,
  disminuirPorCantidad,
  incrementar,
} from "../../../../common/redux/features/contadorItemReducer";
import { useQuery } from "react-query";
import { getPuntosByImporte } from "../../../../services/VenderCryptoService";
import { cliente } from "../../../../common/constants/constants";
import { AppContext } from "../../../../context/AppContext";
import React from "react";
import { queries } from "@testing-library/react";

const CarritoItemContainer = () => {
  const carrito = useSelector((state: RootState) => state.carrito);
  const dispatch = useDispatch();

  const handleIncrementar = (index: number) => {
    dispatch(increament(index));
    dispatch(incrementar());
  };
  const handleDecrementar = (index: number) => {
    dispatch(decrement(index));
    dispatch(disminuir());
  };

  const handleRemove = (index: number, cantidad: number) => {
    dispatch(removeItem(index));
    dispatch(disminuirPorCantidad(cantidad));
  };

  return (
    <>
      {carrito.map((item, index) => (
        <Grid
          key={`${item.idProducto}-${index}`}
          container
          spacing={1}
          columns={14}
          className={
            index % 2
              ? "row-carrito row-carrito-gris"
              : " row-carrito row-carrito-blanco"
          }
        >
          <Grid item xs={12} sm={2} md={2}>
            <Box className="column-carrito">
              <Typography variant="h6" color="initial">
                {new Date(Date.now()).toLocaleDateString()}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <Box className="column-carrito">
              <Typography variant="h6" color="initial">
                {item.esGiftCard ? `Gift Card - ${item.Precio} $` : item.Nombre}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <Box className="column-carrito">
              <Typography variant="h6" color="initial">
                {item.Marca}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <Box className="column-carrito column-btn-carrito-table">
              <Button
                disabled={item.cantidad === 1}
                sx={{
                  maxWidth: "20px",
                  minWidth: "20px",
                  maxHeight: "20px",
                  minHeight: "20px",
                }}
                variant="contained"
                color="btnPrimary"
                onClick={() => handleDecrementar(index)}
              >
                -
              </Button>
              <Typography variant="h6" color="initial">
                {item.cantidad}
              </Typography>
              <Button
                sx={{
                  maxWidth: "20px",
                  minWidth: "20px",
                  maxHeight: "20px",
                  minHeight: "20px",
                }}
                variant="contained"
                onClick={() => handleIncrementar(index)}
              >
                +
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <Box className="column-carrito">
              <Typography variant="h6" color="initial">
                {item.puntosPorUnidad}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <Box className="column-carrito">
              <Typography variant="h6" color="initial">
                {item.puntos}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <Box className="column-carrito">
              <Button
                variant="text"
                onClick={() => handleRemove(index, item.cantidad)}
              >
                <DeleteIcon />
              </Button>
            </Box>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default CarritoItemContainer;
