import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Typography } from "@mui/material";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import { useNavigate } from "react-router-dom";
import ES from "../../../common/language/shared/es.json";
import EN from "../../../common/language/shared/en.json";
import useLanguage from "../../../common/hooks/useLanguage";
import { AppContext } from "../../../context/AppContext";
import { RECONOCIMIENTO_SUB_MENU } from "../../../common/constants/rutas";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export default function MenuReconocimiento() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const appContext = React.useContext(AppContext);

  const { dictionary } = useLanguage({ EN, ES });
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };
  const handleClose = (ruta?: string) => {
    setAnchorEl(null);
    ruta && navigate(ruta);
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="text"
        size="small"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          textTransform: "capitalize",
          color: "white",
          margin: 0,
          padding: "0px !important",
        }}>
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            fontFamily: "'Montserrat',sans-serif",
            fontSize: 14,
            gap: "5px",
          }}
          flexDirection={{ xs: "column", sm: "column", md: "row" }}>
          <EmojiEventsOutlinedIcon sx={{ fontSize: "20px" }} />
          {dictionary?.header.reconocimientos}{" "}
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        sx={{ mt: 1 }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}>
        {RECONOCIMIENTO_SUB_MENU.filter((el) =>
          appContext?.permisos?.find((permiso) => permiso.codigo === el.codigo)
        ).map((el) => (
          <MenuItem
            key={`menu-reco${el.codigo}`}
            onClick={() => handleClose(el.ruta)}>
            <Typography color="default">
              {dictionary?.header[el.dictionario]}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
