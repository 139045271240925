import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputForm from "../../../../components/Inputs/InputForm/InputForm";
import {
  PaisInterface,
  localidadInterface,
  provinciaInterface,
} from "../../../../common/interfaces/MisDatosInterface";
import { useEffect, useState } from "react";
import SelectForm from "../../../../components/Inputs/SelectForm/SelectForm";
import { Box, Button } from "@mui/material";
import {
  GetLocalidadByProvinciaForSelect,
  getPaisForSelect,
  getProvinciaForSelect,
} from "../../../../services/MisDatosService";
import useCarrito from "../../../../common/hooks/useCarrito";

type Props = {
  form: any;
  setForm: React.Dispatch<React.SetStateAction<any>>;
  err?: boolean;
};

const styleAcordion = {
  maxWidth: "600px",
  width: "100%",

  backgroundColor: "rgb(219, 219, 219)",
};

export const PuntoEntregaDatos: React.FC<Props> = ({
  form,
  setForm,
  err = false,
}) => {
  const [pais, setPais] = useState<PaisInterface[] | null>(null);
  const [provincia, setProvincia] = useState<provinciaInterface[] | null>(null);
  const [localidades, setLocalidades] = useState<localidadInterface[] | null>();
  const { dictionary } = useCarrito();

  useEffect(() => {
    getPaisForSelect()
      .then((paisData) => setPais(paisData))
      .catch((err) => setPais(null));
    getProvinciaForSelect()
      .then((provData) => setProvincia(provData))
      .catch((err) => setProvincia(null));
  }, []);

  useEffect(() => {
    form.idProvincia &&
      form.idProvincia.trim() !== "" &&
      GetLocalidadByProvinciaForSelect(form.idProvincia)
        .then((localidadesData) => setLocalidades(localidadesData))
        .catch((err) => setLocalidades(null));
  }, [form.idProvincia]);

  if (form.idFormaEntrega === 1) {
    setForm({
      ...form,
      domicilio: null,
      calle: "",
      calleNumero: undefined,
      departamento: "",
      piso: "",
      codigoPostal: "",
      idPais: undefined,
      idProvincia: undefined,
      idLocalidad: undefined,
    });
  }

  const handleNew = () => {
    setForm({
      ...form,
      domicilio: null,
      calle: "",
      calleNumero: undefined,
      departamento: "",
      piso: "",
      codigoPostal: "",
      idPais: undefined,
      idProvincia: undefined,
      idLocalidad: undefined,
    });
  };

  useEffect(() => {
    form.idProvincia &&
      form.idProvincia.trim() !== "" &&
      GetLocalidadByProvinciaForSelect(form.idProvincia)
        .then((localidadesData) => setLocalidades(localidadesData))
        .catch((err) => setLocalidades(null));
  }, [form.idProvincia]);

  return (
    <Accordion sx={styleAcordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Typography>
          {dictionary?.formCarrito.row2.body.datosPuntoEntrega}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {form.idFormaEntrega === 1 && (
          <Box textAlign="right">
            <Button variant="contained" onClick={handleNew}>
              {dictionary?.formCarrito.validaciones.agregar}
            </Button>
          </Box>
        )}
        {!form.domicilio ? (
          <>
            <InputForm
              required={true}
              err={err}
              readonly={form.idFormaEntrega === 2 ? true : false}
              name={"calle"}
              label={dictionary?.formCarrito.row2.body.calle}
              type={"text"}
              form={form}
              setForm={setForm}
            />
            <InputForm
              err={err}
              required={true}
              readonly={form.idFormaEntrega === 2 ? true : false}
              name={"calleNumero"}
              label={dictionary?.formCarrito.row2.body.calleNumero}
              type={"number"}
              form={form}
              setForm={setForm}
            />
            <InputForm
              err={err}
              required={true}
              readonly={form.idFormaEntrega === 2 ? true : false}
              name={"piso"}
              label={dictionary?.formCarrito.row2.body.piso}
              type={"text"}
              form={form}
              setForm={setForm}
            />
            <InputForm
              readonly={form.idFormaEntrega === 2 ? true : false}
              name={"departamento"}
              label={dictionary?.formCarrito.row2.body.departamento}
              type={"text"}
              form={form}
              setForm={setForm}
            />
          </>
        ) : (
          <>
            <InputForm
              err={err}
              required={true}
              readonly={
                form.idFormaEntrega === 2 || form.domicilio ? true : false
              }
              name={"domicilio"}
              label={dictionary?.formCarrito.row2.body.domicilio}
              type={"text"}
              form={form}
              setForm={setForm}
            />
          </>
        )}
        <InputForm
          err={err}
          required={true}
          readonly={form.idFormaEntrega === 2 || form.domicilio ? true : false}
          name={"codigoPostal"}
          label={dictionary?.formCarrito.row2.body.codigoPostal}
          type={"text"}
          form={form}
          setForm={setForm}
        />
        <SelectForm
          readonly={form.idFormaEntrega === 2 || form.domicilio ? true : false}
          form={form}
          err={err}
          required={true}
          setForm={setForm}
          label={dictionary?.formCarrito.row2.body.pais}
          children={
            pais &&
            form.calle &&
            form.calleNumero &&
            form.piso &&
            form.departamento &&
            form.codigoPostal ? (
              pais.map((el, index) => (
                <option value={el.id} key={`${el.id}-${index}`}>
                  {el.nombre}
                </option>
              ))
            ) : (
              <option value={undefined}>Seleccionar</option>
            )
          }
          name={"idPais"}
        />
        <SelectForm
          required={true}
          readonly={form.idFormaEntrega === 2 || form.domicilio ? true : false}
          form={form}
          err={err}
          setForm={setForm}
          label={dictionary?.formCarrito.row2.body.provincia}
          children={
            provincia &&
            form.calle &&
            form.calleNumero &&
            form.piso &&
            form.departamento &&
            form.codigoPostal ? (
              provincia.map((el, index) => (
                <option value={el.id} key={`${el}-${index}`}>
                  {el.nombre}
                </option>
              ))
            ) : (
              <option value={undefined}>Seleccionar</option>
            )
          }
          name={"idProvincia"}
        />
        <SelectForm
          required={true}
          readonly={form.idFormaEntrega === 2 || form.domicilio ? true : false}
          form={form}
          err={err}
          setForm={setForm}
          label={dictionary?.formCarrito.row2.body.localidad}
          children={
            localidades &&
            form.calle &&
            form.calleNumero &&
            form.piso &&
            form.departamento &&
            form.codigoPostal ? (
              localidades.map((el: localidadInterface, index) => (
                <option value={el.id} key={`${el.id}-${index}`}>
                  {el.nombre}
                </option>
              ))
            ) : (
              <option value={undefined}>Seleccionar</option>
            )
          }
          name={"idLocalidad"}
        />
      </AccordionDetails>
    </Accordion>
  );
};
