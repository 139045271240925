import Typography from "@mui/material/Typography";
import { useContext, useEffect, useState } from "react";
import {
  GetDomicilioEntrega,
  GetPuntoEntrega,
} from "../../../../services/CarritoService";
import { modalError } from "../../../../utils/validaciones";
import { AppContext } from "../../../../context/AppContext";
import { Box } from "@mui/material";
import {
  IDomicilio,
  IFormCarrito,
  IPuntoEntrega,
} from "../../../../common/interfaces/CarritoInterface";
import useCarrito from "../../../../common/hooks/useCarrito";

type Props = {
  idFormaEntrega: number | null;
  form: IFormCarrito;
  setForm: React.Dispatch<React.SetStateAction<any>>;
};
const SelectPuntoEntrega: React.FC<Props> = ({
  idFormaEntrega,
  setForm,
  form,
}) => {
  const [deliveryPoints, setDeliveryPoints] = useState<IPuntoEntrega[] | null>(
    null
  );

  const [domicilios, setDomicilios] = useState<IDomicilio[] | null>(null);
  const [optionSelect, setOptionSelect] = useState<number | string>(-1);

  const appContext = useContext(AppContext);
  const { dictionary } = useCarrito();

  useEffect(() => {
    setDeliveryPoints(null);
    setForm((prev) => ({ idFormaEntrega: prev.idFormaEntrega }));
    if (idFormaEntrega !== 3) {
      if (idFormaEntrega === 2) {
        //punto por el cliente
        GetPuntoEntrega()
          .then((data) => setDeliveryPoints(data))
          .catch(() => modalError("Error al traer los puntos de entrega"));
      } else if (idFormaEntrega === 1)
        GetDomicilioEntrega(appContext?.user?.idPersona)
          .then((data) => {
            data = data.map((el: IDomicilio) => {
              return { ...el, id: crypto.randomUUID() };
            });
            setDomicilios(data);
          })
          .catch((err) => {
            if (err.status === 404) {
              modalError("Error al traer los domicilios");
            }
          });
    }
  }, [idFormaEntrega, appContext, setForm]);

  useEffect(() => {
    form.idFormaEntrega === 1 && !form.domicilio && setOptionSelect(-1);
  }, [form]);

  const handlePuntoEntrega = (e: any) => {
    setOptionSelect(e.target.value);
    if (idFormaEntrega === 2) {
      const deliveryPoint = deliveryPoints?.filter(
        (el: IPuntoEntrega) => el.idPuntoEntrega === e.target.value
      );

      if (deliveryPoint) {
        setForm({ ...form, ...deliveryPoint[0], domicilio: null });
      }
    } else {
      const domicilioFilter: IDomicilio[] | undefined = domicilios?.filter(
        (el: IDomicilio) => el.id === e.target.value
      );

      domicilioFilter &&
        setForm({
          ...form,
          domicilio: domicilioFilter[0]?.domicilio,

          codigoPostal: domicilioFilter[0].cp,
          idPais: domicilioFilter[0].idPais,
          idProvincia: domicilioFilter[0].idProvincia,
          idLocalidad: domicilioFilter[0].idLocalidad,
        });
    }
  };
  return (
    <>
      {idFormaEntrega && idFormaEntrega !== 3 && (
        <Box sx={{ width: "100%" }}>
          <Typography variant="h6" color="initial">
            {dictionary?.formCarrito.row2.body.puntoEntrega}
          </Typography>
          <select
            className={`select-carrito`}
            value={optionSelect}
            onChange={handlePuntoEntrega}>
            <option selected disabled value={-1}>
              Elija una opción
            </option>
            {idFormaEntrega === 2
              ? deliveryPoints &&
                deliveryPoints.map((el: IPuntoEntrega) => (
                  <option
                    key={`punto-entrega-${el.idPuntoEntrega}`}
                    value={el.idPuntoEntrega}>
                    {el.descripcion}
                  </option>
                ))
              : domicilios &&
                domicilios.map((el: IDomicilio, index: number) => (
                  <option key={el.id} value={el.id}>
                    {el.domicilio}
                  </option>
                ))}
          </select>
        </Box>
      )}
    </>
  );
};

export default SelectPuntoEntrega;
